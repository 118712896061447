import React from 'react';
import styled from 'styled-components';
import {Button, Form, Input, Collapse} from 'antd';
import {useOutlet} from 'reconnect.js';
const {Panel} = Collapse;

function callback(key) {
  console.log(key);
}

const content = [
  {
    question: `購物流程`,
    ans: `登入會員→選購商品→加入購物車→填寫/確認寄件資料→前往結帳→完成購物。`,
  },
  {
    question: `付款方式`,
    ans: `1.ATM轉帳付款:交易完成後，會產生一組帳號為虛擬帳號，需於 3 天內完成轉帳作業，過期即會失效，需重新下單。
2.信用卡線上付款:接受各家銀行 Visa/Master/JCB 等信用卡 (需一次付清)。
3.貨到付款(超商取貨):貨物送達後，直接支付現金給宅配人員/門市人員即可。`,
  },
  {
    question: `配送與運費`,
    ans: `1.宅配到府(台灣本島運費120元、非台灣本島250元)。
2.超商取貨運費80元(目前合作超商為7-11、全家便利商店)。
3.國外運費：順豐貨運配送到府(香港、澳門運費500元，其他地區歡迎直接聯繫我們)。`,
  },
  {
    question: `國外買家如何付款`,
    ans: `【信用卡線上付款】使用歐付寶第三方支付平台系統，凡各家銀行的 VISA、MASTER 及 JCB 信用卡皆可使用，如需使用其它支付方式，請先來訊洽詢。依送往國家之法律及規定，可能徵收進口關稅、增值稅、通關手續費、郵費等，如需徵收前述費用，由顧客自行負擔。`,
  },
  {
    question: `昨天才購買商品，結果今天就降價了?`,
    ans: `微媞網購商城提供不定期與主題促銷等活動，參與活動的商品會在不同的促銷中以不同價格出售，皆為正常價格調動，恕不提供退補差價服務;活動頁面上均有促銷活動等折扣相關明確說明，且活動結束後將不再享有活動價格，敬請見諒。`,
  },
  {
    question: `沒有在領取的期限內取貨該怎麼辦?`,
    ans: `若您逾期未至門市取貨，商品將會被退回，訂單亦將自動取消且無法重新配送，如您仍需要該商品，請再次重新上網訂購。

※提醒您，若您有 2 次以上(含 2 次)未於通知期限內至指定門巿取貨，未來您將無法使用便利商店取貨服務。請您與我們共同珍惜寶貴的訂購資源及維護自身權益。`,
  },
  {
    question: `鑑賞期如何計算?`,
    ans: `鑑賞期是指猶豫期而非試用期。微媞網購商城之鑑賞期為 7 天，計算方式以簽收隔日為第一天(不論簽收人是否為買家本人，只要簽收便開始計算鑑賞期)。`,
  },
  {
    question: `退/換貨需知`,
    ans: `依消費者保護法規定，商品未經拆封、使用、非人為瑕疵造成污損、故障，或是其他任何因素退貨，產品必頇恢復原狀，亦即必需恢復至您收到商品時的原始狀態且包裝完整(保持產品、贈品、附件、包裝、紙箱及所有相關文件、資料之完整性)。
請務必留意!申請退/換貨以商品瑕疵、出貨有誤為主，若超過七日鑑賞期(含例假日)或產品已拆封使用過，請恕無法接受退/換貨服務，請於到貨後確實驗收。如需退/換貨，以原訂單寄件地址為主，如需修改收件資料、地址等導致影響寄送費用，則需自行負擔額外運費。`,
  },
];

function FaqCollapse() {
  const [dimension] = useOutlet('dimension');

  return (
    <Collapse onChange={callback} expandIconPosition="right" ghost>
      {content.map((item, idx) => (
        <ArticleRow
          dimension={dimension}
          header={
            <div className="row" style={{alignItems: 'center'}}>
              <div className="id">{`${idx + 1}.  `} </div>
              <div className="faq-title">{item.question}</div>
            </div>
          }
          key={idx}>
          <p className="faq-content">{item.ans}</p>
        </ArticleRow>
      ))}
    </Collapse>
  );
}

const ArticleRow = styled(Collapse.Panel)`
  background-color: #fff;
  padding: 10px;
  margin-top: 20px;
  border-radius: 8px;

  & .faq-title {
    font-size: ${(props) =>
      props.dimension.rwd === 'desktop' ? '20px' : '16px'};
    letter-spacing: 2.57px;
    font-weight: 500;
    color: var(--contentTextColor);
  }

  & .faq-content {
    letter-spacing: 2.57px;
    margin-left: 28px;
    padding-top: 15px;
    border-top: 1px solid #ccc;
    font-size: 16px;
    color: #777a76;
    white-space: break-spaces;
  }

  & .id {
    margin-right: 5px;
    color: var(--primaryColor);
    font-weight: bold;
    white-space: pre;
  }
`;

export default FaqCollapse;
